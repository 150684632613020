import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import App from "./App";
import Styles from "./styles";
import theme from "./theme";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://9eda64fec1ba49cb968023fc3c738eab@sentry.io/5172582",
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Styles />
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
