import * as Sentry from "@sentry/browser";
import Axios from "axios";
import { mergeDeepRight } from "ramda";
import React, { useEffect, useState } from "react";
import * as FlexWebChat from "@twilio/flex-webchat-ui";
import { appConfig, defaultMessages } from "./flexConfig";
import RestartChatBtn from "./RestartChatBtn";
import { awaitTimeout, sound, to } from "../../utils";

const RESET_BUTTON_KEY = `restartButton`;

const setPluginDefaults = (plugin, obj) => {
  const { defaultProps } = FlexWebChat[plugin];
  FlexWebChat[plugin].defaultProps = mergeDeepRight(defaultProps, obj);
};

export default () => {
  const [error, setError] = useState();
  const [loaded, setLoaded] = useState();
  const [manager, setManager] = useState();

  setPluginDefaults("MainHeader", {
    imageUrl: "/logo192.png",
    titleText: "Lifeline ISS",
  });

  setPluginDefaults("MessagingCanvas", {
    autoInitChannel: true,
    memberDisplayOptions: {
      yourFriendlyNameOverride: true,
    },
  });

  const getChannelSid = () => manager.store.getState().flex.session.channelSid;

  const onClick = async () => {
    const channel = await manager.chatClient.getChannelBySid(getChannelSid());
    channel.sendMessage("system note: The chat will end in 3 seconds");
    await awaitTimeout(3000);
    await to(
      Axios.get(
        `https://${
          process.env.REACT_APP_FLEX_RUNTIME_DOMAIN
        }.twil.io/getTaskByChatChannel?channelSid=${getChannelSid()}`
      )
    );

    await awaitTimeout(500);

    FlexWebChat.Actions.invokeAction("RestartEngagement");
    return localStorage.clear();
  };

  const bindSoundOnChannelReady = (response) => {
    const onMessageListener = () => {
      response.chatClient.channels.on("messageAdded", (data) => {
        if (data.author !== response.chatClient.user.identity) {
          sound.play();
        }
      });
    };

    if (response.chatClient) {
      return onMessageListener();
    }

    FlexWebChat.Actions.on("afterStartEngagement", onMessageListener);
    FlexWebChat.Actions.on("beforeStartEngagement", ({ formData }) => {
      Sentry.setExtra("friendlyName", formData.friendlyName);
    });
  };

  const loadConfig = async () => {
    const [e, response] = await to(FlexWebChat.Manager.create(appConfig));

    if (e) {
      return setError(e);
    }
    Object.assign(response.strings, defaultMessages);
    setManager(response);
    bindSoundOnChannelReady(response);
  };

  useEffect(() => {
    loadConfig();
  }, []);

  useEffect(() => {
    if (manager && !loaded) {
      FlexWebChat.MessageInput.Content.add(
        <RestartChatBtn key={RESET_BUTTON_KEY} onClick={onClick} />
      );
      setLoaded(true);
    }
  }, [manager]);

  if (manager) {
    return (
      <FlexWebChat.ContextProvider manager={manager}>
        <FlexWebChat.RootContainer />
      </FlexWebChat.ContextProvider>
    );
  } else {
    return null;
  }
};
